td {
    vertical-align: bottom;
}

td ul {
    margin-bottom: 0;
    margin-right: 30px;
}

.header-image {
    margin-top: 120px;

    @include media-breakpoint-up(lg) {
        margin-top: 180px;
    }
}


.accordion-content {
    table {
        margin-bottom: 15px;
    }
}
@include media-breakpoint-down(xs) {
    #accordion-item-price {
        table tr {
            td:last-child {
                width: 20%;
            }
        }
    }
}

.w4-price-booking-container {
    font-weight: $font-weight-bold;
    font-family: $font-family-futurapt;
    color: $primary;
    margin-bottom: 40px;

    span {
        display: block;
    }

    .price-line-big {
        font-size: 3.125rem;
        margin-top: 10px;
    }
    .price-line-small {
        font-size: 1.375rem;
    }

    .w4-button-anfrage {
        margin-top: 40px;
    }

    .w4-price-text {
        font-family: $font-family-raleway;
        font-size: $font-size-base;
        color: $secondary;
    }
}

.w4-button-anfrage {
    text-align: center;
}

.w4-package-filter {
    .mix {
        padding-left: 0;
        padding-right: 0;
    }
}

.teaser-price-line {
    display: inline-block;
    line-height: 1.3;

    &::after {
        content: ' ';
        display: block;
        width: 0;
        border-bottom: 1px solid $primary;
        transition: width 1s;
    }
}

.card-group-element a:hover {
    .teaser-price-line {
        &::after {
            width: 100%;
        }
    }
}

.splide {
    width: 100%;
}

.package-filter {
    margin-bottom: 2.188rem;

    ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 0;

        li {
            padding-left: 0 !important;
            text-align: center;

            &::before {
                content: '' !important;
            }

            &.mixitup-control-active {
                button {
                    color: $primary;
                    text-decoration: underline;
                }
            }

            button {
                border: 0;
                background: none;
                text-decoration: none;
                text-transform: uppercase;
                font-family: $font-family-futurapt;
                font-size: 1.563rem;
                color: $gray-555;
                line-height: 1.4;

                &:hover {
                    text-decoration: underline;
                }

                &:focus {
                    border: 0;
                    outline: 0;
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .package-filter {
        margin-bottom: 3.376rem;

        ul {
            flex-direction: row;

            li {
                padding: 0 1.563rem;
            }
        }
    }
}

.tx-wvier-packages .card-group-element {
    margin: -8px;
}

@include media-breakpoint-up(md) {
    .w4-package-start {
        .splide__list {
            width: auto;
            display: flex;
            flex-wrap: wrap;
        }

        .splide__arrows {
            display: none;
        }
    }
}

@include media-breakpoint-up(lg) {
    .splide__slide {
        padding-left: 0;
        padding-right: 0;
    }
}

@include media-breakpoint-down(xs) {
    .w4-package-start {
        .splide__slide {
            opacity: 1;
            transition: opacity 0.2s;
        }

        .splide__slide:not(.is-active) {
            opacity: 0.5;
            transition: opacity 0.2s;
        }
    }
}

@include media-breakpoint-down(md) {
    .w4-package-start {
        .splide__track {
            overflow: visible;
        }

        .package-splide {
            width: 100%;
        }

        .splide__arrows {
            padding-left: 18px;
        }

        .splide__arrow {
            display: inline-block;
            align-items: center;
            justify-content: center;
            height: 50px;
            width: auto;
            border: 0;
            padding: 0;
            background: transparent;
            color: $primary;
            transition: color 0.25s ease-in-out;

            &:disabled {
                .package-svg {
                    svg {
                        stroke: rgba($gray-555, 0.3);
                    }
                }
            }

            .package-svg {
                stroke: $gray-555;
                display: block;
                height: 75px;
                margin-top: -20px;
            }
        }

        .splide__arrow--prev {
            svg {
                transform:scaleX(-1);
            }
        }
    }
}
